<template>
  <div>
    <b-card>
      <div class="row mb-4">
        <div class="col-12">
          <modal-actions @close="$router.push({ name: 'patrimony' })" />
        </div>
      </div>
      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <p class="h2 text-secondary">
            <i class="mdi mdi-cube-outline"></i> Modifier patrimoine
          </p>
          <b-button
            v-if="editMode"
            variant="secondary"
            @click="editMode = !editMode"
            >Confirmer</b-button
          >
          <hr class="w-100" />
        </div>

        <div class="row">
          <!-- First Col -->
          <div class="col-12">
            <b-form-group
              label-cols="12"
              label="Libellé"
              label-class="font-weight-bold"
            >
              <b-form-input
                type="text"
                value="Libellé patrimoine"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Responsable"
              label-class="font-weight-bold"
            >
              <b-form-input type="text" value="Steve EWORE"></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label="Description"
              label-class="font-weight-bold"
            >
              <b-form-textarea
                value="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Est illo praesentium, incidunt aut itaque cum."
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="row my-5">
        <div class="col-12">
          <b-tabs
            :value="$store.state.tabs.activeTab"
            @changed="activeTab = $store.state.tabs.activeTab"
            @activate-tab="
              (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
            "
            class="tab-solid tab-solid-primary mt-3"
          >
            <b-tab title="Référents">
              <patrimony-referent />
            </b-tab>
            <b-tab title="Les processus">
              <patrimony-process />
            </b-tab>
            <b-tab title="Les applications BL">
              <patrimony-applications />
            </b-tab>
            <b-tab title="Les FDR de la BL">
              <patrimony-fdr />
            </b-tab>

            <b-tab title="Transformation Map">
              <patrimony-transformation-map />
            </b-tab>

            <b-tab title="KPI">
              <patrimony-kpi />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import PatrimonyApplications from "./components/PatrimonyApplications.vue";
import PatrimonyTransformationMap from "./components/PatrimonyTransformationMap.vue";
import PatrimonyProcess from "./components/PatrimonyProcess.vue";
import PatrimonyReferent from "./components/PatrimonyReferent.vue";
import PatrimonyFdr from "./components/PatrimonyFdr.vue";
import PatrimonyKpi from "./components/PatrimonyKpi.vue";

export default {
  components: {
    ModalActions,
    PatrimonyApplications,
    PatrimonyTransformationMap,
    PatrimonyProcess,
    PatrimonyReferent,
    PatrimonyFdr,
    PatrimonyKpi,
  },
  data: () => ({
    editMode: false,
  }),
  methods: {},
};
</script>

<style></style>
